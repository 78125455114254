import { SvgIcon, SvgIconProps } from '@material-ui/core';

import React from 'react';

export const BitwardenIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 512 512">
    <rect width="512" height="512" rx="15%" fill="#175DDC" />
    <path
      fill="#ffffff"
      d="M372 297V131H256v294c47-28 115-74 116-128zm49-198v198c0 106-152 181-165 181S91 403 91 297V99s0-17 17-17h296s17 0 17 17z"
    />
  </SvgIcon>
);
