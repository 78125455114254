import { SvgIcon, SvgIconProps } from '@material-ui/core';

import React from 'react';

export const UnleashIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="45.715 45.515 160 160">
    <circle fill="currentColor" cx="125.71" cy="125.31" r="80" />
    <polygon
      fill="currentColor"
      style={{ filter: 'invert(100%)' }}
      points="137.14 91.03 137.14 113.88 137.14 136.74 160 136.74 160 113.88 160 91.03 137.14 91.03"
    />
    <polygon
      fill="currentColor"
      style={{ filter: 'invert(100%)' }}
      points="114.29 113.88 114.29 91.03 91.43 91.03 91.43 113.88 91.43 136.74 91.43 159.6 114.29 159.6 137.14 159.6 137.14 136.74 114.29 136.74 114.29 113.88"
    />
    <rect fill="#817afe" x="137.14" y="136.74" width="22.86" height="22.86" />
  </SvgIcon>
);
