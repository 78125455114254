import { SvgIcon, SvgIconProps } from '@material-ui/core';

import React from 'react';

export const OpenSearchIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 64 64">
    <path
      fill="#00A3E0"
      d="M61.74 23.5a2.26 2.26 0 0 0-2.27 2.26 33.71 33.71 0 0 1-33.7 33.71 2.26 2.26 0 1 0 0 4.53A38.24 38.24 0 0 0 64 25.76a2.26 2.26 0 0 0-2.26-2.26Z"
    />
    <path
      fill="#00A3E0"
      d="M3.92 14A24.43 24.43 0 0 0 .05 28.9c.86 13.73 13.3 24.14 25.03 23.02 4.6-.45 9.31-4.2 8.9-10.9-.19-2.92-1.62-4.64-3.93-5.96C27.84 33.8 25 33 21.79 32.1c-3.89-1.1-8.4-2.32-11.85-4.87-4.15-3.06-6.99-6.6-6.02-13.23Z"
    />
    <path
      fill="#B9D9EB"
      d="M48.08 38a24.43 24.43 0 0 0 3.87-14.9C51.09 9.36 38.65-1.05 26.92.07c-4.6.45-9.31 4.2-8.9 10.9.19 2.92 1.62 4.64 3.93 5.96C24.16 18.2 27 19 30.21 19.9c3.89 1.1 8.4 2.32 11.85 4.87 4.15 3.06 6.99 6.6 6.02 13.23Z"
    />
  </SvgIcon>
);
