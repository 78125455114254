import { SvgIcon, SvgIconProps } from '@material-ui/core';

import React from 'react';

export const KafkaUiIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 23 30">
    <path
      d="M2.17668 0C2.17668 0 8.45218 9.02115 19.6155 13.3524C19.6155 13.3524 27.0635 7.06532 19.862 16.1041C12.6605 25.1428 1.6961 30.617 2.17668 29.9444C3.60584 27.9442 8.31948 24.1222 5.91024 21.7649C10.6395 17.1375 0 14.0868 0 14.0868C2.75705 8.06572 2.17668 0 2.17668 0Z"
      fill="#4F4FFF"
    />
  </SvgIcon>
);
