import {
  ConfigApi,
  createApiRef,
  DiscoveryApi,
  type FetchApi,
} from '@backstage/core-plugin-api';

const DEFAULT_PROXY_PATH = '/prometheus/api';
const SERVICE_NAME_HEADER = 'x-prometheus-service-name';

type Options = {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
  configApi: ConfigApi;
};

export const prometheusApiRef = createApiRef<PrometheusApi>({
  id: 'plugin.prometheus.service',
});

export class PrometheusApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly configApi: ConfigApi;
  private readonly fetchApi: FetchApi;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    this.configApi = options.configApi;
    this.fetchApi = options.fetchApi;
  }

  private async getApiUrl({ serviceName }: { serviceName?: string }) {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return `${proxyUrl}${this.getProxyPath({ serviceName })}`;
  }

  public getUiUrl({ serviceName }: { serviceName?: string }) {
    if (Boolean(serviceName)) {
      const instances = this.configApi.getOptionalConfigArray(
        'prometheus.instances',
      );
      if (instances && instances?.length > 0) {
        const instance = instances.find(
          value => value.getString('name') === serviceName,
        );
        if (Boolean(instance)) {
          return instance?.getOptionalString('uiUrl');
        }
      }
    }
    return this.configApi.getOptionalString('prometheus.uiUrl');
  }

  private getProxyPath({ serviceName }: { serviceName?: string }) {
    if (Boolean(serviceName)) {
      const instances = this.configApi.getOptionalConfigArray(
        'prometheus.instances',
      );
      if (instances && instances?.length > 0) {
        const instance = instances.find(
          value => value.getString('name') === serviceName,
        );
        if (Boolean(instance)) {
          // @ts-ignore
          return instance.getString('proxyPath');
        }
      }
    }
    return (
      this.configApi.getOptionalString('prometheus.proxyPath') ||
      DEFAULT_PROXY_PATH
    );
  }

  async getAlerts({ serviceName }: { serviceName?: string }) {
    const apiUrl = await this.getApiUrl({ serviceName });
    const response = await this.fetchApi.fetch(`${apiUrl}/rules?type=alert`, {
      headers: {
        [SERVICE_NAME_HEADER]: serviceName || '',
      },
    });

    if (!response.ok) {
      throw new Error(
        `failed to fetch data, status ${response.status}: ${response.statusText}`,
      );
    }
    return response.json();
  }
}
