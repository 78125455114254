import React from 'react';
import { Route } from 'react-router-dom';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
  CatalogTable,
  CatalogTableColumnsFunc,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { AppRouter, FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { RequirePermission } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { microsoftAuthApiRef } from '@backstage/core-plugin-api';

import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';

import { PrometheusPage } from '@internal/backstage-plugin-prometheus';
import { DeploymentTrackerPage } from '@internal/backstage-plugin-deployment-tracker';

import { HomepageCompositionRoot, VisitListener } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';

import { OpsGenieIcon } from './assets/icons/OpsGenieIcon';
import { ConfluenceIcon } from './assets/icons/ConfluenceIcon';
import { JiraIcon } from './assets/icons/JiraIcon';
import { PrometheusIcon } from './assets/icons/PrometheusIcon';
import { KafkaUiIcon } from './assets/icons/KafkaUiIcon';
import { MiroIcon } from './assets/icons/MiroIcon';
import { GrafanaIcon } from './assets/icons/GrafanaIcon';
import { JaegerIcon } from './assets/icons/JaegerIcon';
import { HarborIcon } from './assets/icons/HarborIcon';
import { BugsnagIcon } from './assets/icons/BugsnagIcon';
import { GitlabIcon } from './assets/icons/GitlabIcon';
import { BitwardenIcon } from './assets/icons/BitwardenIcon';
import { UnleashIcon } from './assets/icons/UnleashIcon';
import { ThanosIcon } from './assets/icons/ThanosIcon';
import { LookerStudioIcon } from './assets/icons/LookerStudioIcon';
import { JfrogIcon } from './assets/icons/JfrogIcon';
import { OpenSearchIcon } from './assets/icons/OpenSearchIcon';
import { MsTeamsIcon } from './assets/icons/MsTeamsIcon';
import { SonarQubeIcon } from './assets/icons/SonarQubeIcon';
import {
  EntityKindPicker,
  EntityLifecyclePicker,
  EntityOwnerPicker,
  EntityProcessingStatusPicker,
  EntityTagPicker,
  EntityTypePicker,
  UserListPicker,
} from '@backstage/plugin-catalog-react';
import {
  AwsAccountPicker,
  ServiceCriticalityPicker,
  K8sClusterPicker,
  LanguagePicker,
  ToolingPicker,
} from './components/catalog/custom-filters';
import { VisibilityPicker } from './components/catalog/custom-filters/visibility';

const app = createApp({
  apis,
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
  components: {
    SignInPage: props => (
      <SignInPage
        {...props}
        auto
        provider={{
          id: 'azure-auth-provider',
          title: 'Login',
          message: 'With RTL SSO',
          apiRef: microsoftAuthApiRef,
        }}
      />
    ),
  },
  icons: {
    opsgenie: OpsGenieIcon,
    msteams: MsTeamsIcon,
    confluence: ConfluenceIcon,
    jira: JiraIcon,
    prometheus: PrometheusIcon,
    kafkaui: KafkaUiIcon,
    miro: MiroIcon,
    grafana: GrafanaIcon,
    harbor: HarborIcon,
    jaeger: JaegerIcon,
    bugsnag: BugsnagIcon,
    gitlab: GitlabIcon,
    bitwarden: BitwardenIcon,
    unleash: UnleashIcon,
    thanos: ThanosIcon,
    lookerstudio: LookerStudioIcon,
    jfrog: JfrogIcon,
    opensearch: OpenSearchIcon,
    run: DirectionsRunIcon,
    sonarqube: SonarQubeIcon,
  },
});

const catalogColumns: CatalogTableColumnsFunc = entityListContext => {
  if (
    !entityListContext.filters.kind ||
    entityListContext.filters.kind.value === 'component'
  ) {
    return [
      CatalogTable.columns.createNameColumn(),
      CatalogTable.columns.createSystemColumn(),
      CatalogTable.columns.createOwnerColumn(),
      CatalogTable.columns.createSpecTypeColumn(),
      CatalogTable.columns.createSpecLifecycleColumn(),
      CatalogTable.columns.createMetadataDescriptionColumn(),
    ];
  }
  return CatalogTable.defaultColumnsFunc(entityListContext);
};

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>

    <Route
      path="/catalog"
      element={
        <CatalogIndexPage
          columns={catalogColumns}
          filters={
            <>
              <EntityKindPicker />
              <EntityTypePicker />
              <UserListPicker />
              <EntityOwnerPicker />

              {/* Start of Custom Filter components */}
              <ServiceCriticalityPicker />
              <AwsAccountPicker />
              <K8sClusterPicker />
              <LanguagePicker />
              <ToolingPicker />
              <VisibilityPicker />
              {/* End of Custom Filter components */}

              <EntityTagPicker />
              <EntityLifecyclePicker />
              <EntityProcessingStatusPicker />
            </>
          }
        />
      }
    />

    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />} />
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <Route
      path="/catalog-import"
      element={
        <RequirePermission permission={catalogEntityCreatePermission}>
          <CatalogImportPage />
        </RequirePermission>
      }
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/prometheus" element={<PrometheusPage />} />
    <Route path="/deployments" element={<DeploymentTrackerPage />} />
  </FlatRoutes>
);

export default app.createRoot(
  <>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </>,
);
