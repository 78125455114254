import { SvgIcon, SvgIconProps } from '@material-ui/core';

import React from 'react';

export const BugsnagIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 256 256">
    <rect
      fill="#84A7FF"
      x="6.73102519"
      y="6.73102519"
      width="242.53795"
      height="242.54"
    />
    <path
      d="M242.53795,13.4603495 L242.53795,242.542346 L13.4620504,242.542346 L13.4620504,13.4603495 L242.53795,13.4603495 M256,1.42108547e-14 L0,1.42108547e-14 L0,256 L256,256 L256,1.42108547e-14 L256,1.42108547e-14 Z"
      fill="#173647"
    />
    <path
      d="M95.6603487,36.0812063 L105.30267,41.7070171 L105.380844,117.691854 L128.358521,117.691854 C155.891837,117.492376 166.898155,135.582849 167.143459,154.228624 C167.475023,179.381686 140.666835,197.914245 113.861343,185.336366 C102.303035,179.914863 93.3886678,166.517604 93.0222524,153.720971 L93.0104805,152.687186 L92.9781821,130.06756 L78.330048,130.06756 L78.330048,150.476287 C77.6669194,175.076742 96.0943476,200.86328 127.204785,203.467273 C158.423048,206.082049 182.686547,183.80524 182.686547,153.233931 C182.686547,124.355488 162.496167,104.254065 133.617724,104.254065 L125.692528,104.254065 L125.695224,91.3041867 L130.981383,91.3041867 C166.582765,91.3527083 191.711566,114.758993 194.754949,146.694297 C198.213569,182.961772 171.62999,215.702176 133.334207,216.050543 L132.170163,216.050543 C90.4388847,216.050543 66.7199057,186.635667 66.6821666,152.729846 L66.6821666,117.697245 L93.366355,117.697245 L93.2935726,54.398113 L79.1845674,61.4580069 L79.1845674,104.59102 L67.0946003,104.59102 L67.0946003,52.6189874 L95.6603487,36.0812063 Z M130.188864,130.06756 L105.51293,130.06756 L105.51293,152.716368 C105.510235,167.016764 117.430377,178.050038 131.730772,178.05543 C145.775804,178.058077 155.218426,166.562501 155.237067,155.178271 L155.228709,154.557493 C155.231404,140.257097 144.486564,130.070255 130.188864,130.06756 Z M130.806166,144.281695 C136.128496,144.281695 140.443097,148.596296 140.443097,153.918625 C140.443097,159.240955 136.128496,163.555556 130.806166,163.555556 C125.483837,163.555556 121.169236,159.240955 121.169236,153.918625 C121.169236,148.596296 125.483837,144.281695 130.806166,144.281695 Z"
      fill="#173647"
    />
  </SvgIcon>
);
