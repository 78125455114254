import type { Entity } from '@backstage/catalog-model';
import type {
  CustomEntity,
  CustomMetadataEnvironment,
  EnvironmentDetails,
} from './types';

export function isCustomEntity(entity: Entity): entity is CustomEntity {
  if (!entity.metadata.custom || typeof entity.metadata.custom !== 'object') {
    return false;
  }

  return (
    entity.metadata.custom !== null &&
    typeof entity.metadata.custom === 'object' &&
    isFieldUndefinedOrMatchesFn(
      entity.metadata.custom,
      'service-criticality',
      value => typeof value === 'number',
    ) &&
    isFieldUndefinedOrString(entity.metadata.custom, 'aws-account') &&
    isFieldUndefinedOrString(entity.metadata.custom, 'k8s-cluster') &&
    isFieldUndefinedOrString(entity.metadata.custom, 'unavailability-impact') &&
    isFieldUndefinedOrString(entity.metadata.custom, 'runbook-url') &&
    isFieldUndefinedOrString(entity.metadata.custom, 'dashboard-url') &&
    isFieldUndefinedOrStringArray(entity.metadata.custom, 'languages') &&
    isFieldUndefinedOrStringArray(entity.metadata.custom, 'tooling') &&
    isFieldUndefinedOrStringArray(entity.metadata.custom, 'aliases') &&
    isFieldUndefinedOrMatchesFn(
      entity.metadata.custom,
      'environment',
      isCustomMetadataEnvironment,
    ) &&
    isFieldUndefinedOrMatchesFn(
      entity.metadata.custom,
      'visibility',
      value => value === 'internal' || value === 'public-facing',
    )
  );
}

export function isCustomMetadataEnvironment(
  metadata: unknown,
): metadata is CustomMetadataEnvironment {
  return (
    metadata !== null &&
    typeof metadata === 'object' &&
    isFieldUndefinedOrMatchesFn(metadata, 'dev', isEnvironmentDetails) &&
    isFieldUndefinedOrMatchesFn(metadata, 'preprod', isEnvironmentDetails) &&
    isFieldUndefinedOrMatchesFn(metadata, 'prod', isEnvironmentDetails)
  );
}

export function isEnvironmentDetails(
  metadata: unknown,
): metadata is EnvironmentDetails {
  return (
    metadata !== null &&
    typeof metadata === 'object' &&
    isFieldUndefinedOrString(metadata, 'baseUrl') &&
    isFieldUndefinedOrString(metadata, 'apiDocUrl')
  );
}

export function isFieldUndefinedOrString(
  object: { [key: string]: any },
  key: string,
) {
  return isFieldUndefinedOrMatchesFn(
    object,
    key,
    value => typeof value === 'string',
  );
}

export function isFieldUndefinedOrStringArray(
  object: { [key: string]: any },
  key: string,
) {
  return isFieldUndefinedOrMatchesFn(
    object,
    key,
    value =>
      Array.isArray(value) && value.every(item => typeof item === 'string'),
  );
}

export function isFieldUndefinedOrMatchesFn(
  object: { [key: string]: any },
  key: string,
  fn: (value: any) => boolean,
) {
  return (
    (key in object && (object[key] === undefined || fn(object[key]))) ||
    !(key in object)
  );
}
