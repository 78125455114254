import {
  createApiRef,
  DiscoveryApi,
  type FetchApi,
} from '@backstage/core-plugin-api';
import type { Deployments, Environment } from '../types';
import type { Dayjs } from 'dayjs';

const DEFAULT_PROXY_PATH = '/deployment-tracker';

type Options = {
  discoveryApi: DiscoveryApi;
  fetchApi: FetchApi;
};

export const deploymentTrackerApiRef = createApiRef<DeploymentTrackerApi>({
  id: 'plugin.deployment-tracker.service',
});

export class DeploymentTrackerApi {
  private readonly discoveryApi: DiscoveryApi;
  private readonly fetchApi: FetchApi;

  constructor(options: Options) {
    this.discoveryApi = options.discoveryApi;
    this.fetchApi = options.fetchApi;
  }

  async getLastDeploymentOfService(
    environment: Environment,
    serviceName: string,
    team?: string,
  ): Promise<Deployments> {
    const apiUrl = await this.getApiUrl(environment);
    const query = this.createQuery({ environment, serviceName, team });

    return this.fetch<Deployments>(`${apiUrl}/deployments/last?${query}`);
  }

  async getDeployments(
    environment: Environment,
    serviceName?: string,
    team?: string,
    since?: Dayjs,
  ): Promise<Deployments[]> {
    const apiUrl = await this.getApiUrl(environment);
    const query = this.createQuery({ environment, serviceName, since, team });

    return this.fetch<Deployments[]>(`${apiUrl}/deployments?${query}`);
  }

  private createQuery({
    environment,
    serviceName,
    since,
    team,
  }: {
    environment: Environment;
    serviceName?: string;
    since?: Dayjs;
    team?: string;
  }) {
    let query = `env=${environment}`;

    if (serviceName) {
      query += `&service=${serviceName}`;
    }
    if (since) {
      query += `&since=${since.format('YYYY-MM-DDTHH:mm:ss')}`;
    }
    if (team) {
      query += `&squad=${team}`;
    }

    return query;
  }

  private async fetch<T>(url: string): Promise<T> {
    const response = await this.fetchApi.fetch(url);

    if (!response.ok) {
      throw new Error(
        `Failed to fetch deployments from ${url}: ${response.statusText}`,
      );
    }
    return response.json();
  }

  private async getApiUrl(environment: Environment) {
    const proxyUrl = await this.discoveryApi.getBaseUrl('proxy');
    return `${proxyUrl}${this.getProxyPath(environment)}`;
  }

  private getProxyPath(environment: 'dev' | 'preprod' | 'prod') {
    return `${DEFAULT_PROXY_PATH}/${environment}`;
  }
}
