import {
  configApiRef,
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import { PrometheusApi, prometheusApiRef } from './api';

export const prometheusPlugin = createPlugin({
  id: 'prometheus',
  apis: [
    createApiFactory({
      api: prometheusApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        configApi: configApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, configApi, fetchApi }) =>
        new PrometheusApi({ discoveryApi, configApi, fetchApi }),
    }),
  ],
  routes: {
    root: rootRouteRef,
  },
});

export const PrometheusPage = prometheusPlugin.provide(
  createRoutableExtension({
    name: 'PrometheusPage',
    component: () =>
      import('./components/PrometheusPage').then(m => m.PrometheusPage),
    mountPoint: rootRouteRef,
  }),
);

export const EntityPrometheusAlertsCard = prometheusPlugin.provide(
  createComponentExtension({
    name: 'EntityPrometheusAlertsCard',
    component: {
      lazy: () =>
        import('./components/PrometheusAlerts').then(
          m => m.PrometheusAlertEntityWrapper,
        ),
    },
  }),
);
