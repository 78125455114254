import { SvgIcon, SvgIconProps } from '@material-ui/core';

import React from 'react';

export const MiroIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} viewBox="0 0 320 320">
      <path
        d="M0 80C0 35.8172 35.8172 0 80 0H240C284.183 0 320 35.8172 320 80V240C320 284.183 284.183 320 240 320H80C35.8172 320 0 284.183 0 240V80Z"
        fill="#fd3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M212.744 61H183.695L207.902 103.532L154.646 61H125.598L152.226 112.985L96.5488 61H67.5L96.5488 127.171L67.5 259.5H96.5488L152.226 117.718L125.598 259.5H154.646L207.902 108.265L183.695 259.5H212.744L266 94.0793L212.744 61Z"
        fill="#000"
      />
    </SvgIcon>
  );
};
