import { SvgIcon, SvgIconProps } from '@material-ui/core';

import React from 'react';

export const LookerStudioIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 40 40">
    <path
      d="M19.9088 3.95834C18.3013 3.95834 17.0969 5.1627 17.0969 6.77021C17.0969 7.30604 17.2313 7.84188 17.6328 8.37771L18.8371 7.17336V6.77191C18.8371 6.10169 19.3729 5.56755 20.0415 5.56755C20.71 5.56755 21.2458 6.10339 21.2458 6.77191C21.2458 7.44042 20.71 7.97626 20.0415 7.97626H19.64L18.4357 9.04793C19.7744 9.85083 21.5163 9.58377 22.3192 8.24503C23.1221 6.90629 22.855 5.1644 21.5163 4.3615C21.1148 4.09443 20.579 3.96004 19.9088 3.96004V3.95834Z"
      fill="#AECBFA"
    />
    <path
      d="M18.837 12.1269C18.837 11.1896 18.5699 10.2523 18.0341 9.4494L16.4266 11.0569C16.561 11.4584 16.6937 11.7271 16.6937 12.1286C16.6937 12.7988 16.4266 13.3329 16.0234 13.7361L16.8263 15.8794C18.1651 15.0765 18.8353 13.6034 18.8353 12.1303L18.837 12.1269Z"
      fill="#5E97F6"
    />
    <path
      d="M14.5521 14.4029C13.3477 14.4029 12.276 13.4656 12.276 12.2595C12.276 11.0535 13.2133 9.98351 14.4194 9.98351C14.8208 9.98351 15.3567 10.1179 15.7581 10.385L17.3656 8.91184C16.4283 8.10894 15.491 7.70749 14.4194 7.70749C12.009 7.70749 10 9.71645 10 12.1269C10 14.5373 11.8746 16.5462 14.4194 16.5462C14.6864 16.5462 15.0896 16.5462 15.3567 16.4118L14.5538 14.4029H14.5521Z"
      fill="#5E97F6"
    />
    <path
      d="M20.0431 16.1431C19.1059 16.1431 18.1686 16.2775 17.2313 16.5445L18.4356 19.4908C18.9715 19.4908 19.5073 19.3564 20.0431 19.3564C23.7923 19.3564 26.7385 22.437 26.7385 26.0518C26.7385 29.6665 23.6579 32.7472 20.0431 32.7472C16.4284 32.7472 13.3477 29.6665 13.3477 26.0518C13.3477 23.507 14.6865 21.231 16.9625 20.1593L15.7582 17.213C10.803 19.6251 8.79569 25.6503 11.2044 30.4711C13.6148 35.4263 19.64 37.4336 24.4608 35.0232C29.416 32.6128 31.4233 26.5876 29.0129 21.7668C27.2727 18.4191 23.7906 16.1431 20.0414 16.1431H20.0431Z"
      fill="#4285F4"
    />
  </SvgIcon>
);
