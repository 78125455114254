import { SvgIcon, SvgIconProps } from '@material-ui/core';

import React from 'react';

export const ConfluenceIcon = (props: SvgIconProps) => (
  <SvgIcon {...props} viewBox="0 0 32 32">
    <path
      d="M4.87273 21.5569C4.61902 21.9559 4.32905 22.4274 4.11158 22.7901C3.89411 23.1528 4.00285 23.6243 4.3653 23.8419L9.33088 26.8884C9.69333 27.106 10.1645 26.9972 10.382 26.6345C10.5632 26.3081 10.8169 25.8729 11.1069 25.4014C13.0641 22.1735 15.0576 22.5362 18.6096 24.2408L23.539 26.562C23.9377 26.7434 24.3726 26.562 24.5538 26.1993L26.9098 20.8678C27.091 20.5052 26.9098 20.0337 26.5473 19.8523C25.4962 19.3808 23.4302 18.4016 21.618 17.4949C14.9126 14.267 9.22215 14.4846 4.87273 21.5569Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M27.1274 9.44314C27.3812 9.04419 27.6711 8.57269 27.8886 8.21001C28.1061 7.84732 27.9973 7.37583 27.6349 7.15822L22.6693 4.11165C22.3068 3.89404 21.8356 4.00285 21.6182 4.36553C21.437 4.69195 21.1832 5.12718 20.8933 5.59867C18.936 8.82657 16.9426 8.46389 13.3905 6.75926L8.46118 4.43807C8.06248 4.25673 7.62754 4.43807 7.44632 4.80076L5.09038 10.1322C4.90916 10.4949 5.09038 10.9664 5.45283 11.1478C6.50394 11.6193 8.56992 12.5985 10.3822 13.5052C17.0875 16.6969 22.778 16.5155 27.1274 9.44314Z"
      fill="url(#paint1_linear)"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="26.7906"
        y1="28.4674"
        x2="11.7924"
        y2="19.8546"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0052CC" />
        <stop offset="0.9182" stopColor="#2380FB" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear"
        x1="5.20945"
        y1="2.52323"
        x2="20.2079"
        y2="11.1361"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#0052CC" />
        <stop offset="0.9182" stopColor="#2380FB" />
        <stop offset="1" stopColor="#2684FF" />
      </linearGradient>
    </defs>
  </SvgIcon>
);
