import {
  createApiFactory,
  createComponentExtension,
  createPlugin,
  createRoutableExtension,
  discoveryApiRef,
  fetchApiRef,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';
import {
  DeploymentTrackerApi,
  deploymentTrackerApiRef,
} from './api/DeploymentTrackerApi';

export const deploymentTrackerPlugin = createPlugin({
  id: 'deployment-tracker',
  routes: {
    root: rootRouteRef,
  },
  apis: [
    createApiFactory({
      api: deploymentTrackerApiRef,
      deps: {
        discoveryApi: discoveryApiRef,
        fetchApi: fetchApiRef,
      },
      factory: ({ discoveryApi, fetchApi }) =>
        new DeploymentTrackerApi({ discoveryApi, fetchApi }),
    }),
  ],
});

export const DeploymentTrackerPage = deploymentTrackerPlugin.provide(
  createRoutableExtension({
    name: 'DeploymentTrackerPage',
    component: () =>
      import('./components/DeploymentsPage').then(m => m.DeploymentsPage),
    mountPoint: rootRouteRef,
  }),
);

export const LastDeploymentsTableCard = deploymentTrackerPlugin.provide(
  createComponentExtension({
    name: 'LastDeploymentsTableCard',
    component: {
      lazy: () =>
        import('./components/DeploymentsTableCard').then(
          m => m.DeploymentsTableCard,
        ),
    },
  }),
);
