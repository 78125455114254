import React from 'react';
import { EntityFilter } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { isCustomEntity } from '@internal/backstage-plugin-custom-entity-metadata-common';
import { CustomEntityAutocompletePicker } from '.';

export class ToolingFilter implements EntityFilter {
  constructor(readonly values: string[]) {}
  getCatalogFilters(): Record<string, string[]> {
    return { 'metadata.custom.tooling': this.values };
  }

  filterEntity(entity: Entity): boolean {
    if (!this.values.length) {
      return true;
    }

    if (!isCustomEntity(entity)) {
      return false;
    }

    return this.values.some(v => entity.metadata.custom.tooling?.includes(v));
  }

  toQueryValue(): string {
    return this.values.join(',');
  }
}

export const ToolingPicker = () => (
  <CustomEntityAutocompletePicker
    label="Tooling"
    name="tooling"
    path="metadata.custom.tooling"
    Filter={ToolingFilter}
  />
);
