import {
  AllowedEntityFilters,
  DefaultEntityFilters,
  EntityAutocompletePicker,
} from '@backstage/plugin-catalog-react';
import { ServiceCriticalityFilter } from './service-criticality';
import { LanguageFilter } from './languages';
import { K8sClusterFilter } from './k8s-cluster';
import { AwsAccountFilter } from './aws-account';
import { ToolingFilter } from './tooling';
import { VisibilityFilter } from './visibility';

export { AwsAccountFilter, AwsAccountPicker } from './aws-account';
export { K8sClusterFilter, K8sClusterPicker } from './k8s-cluster';
export { LanguageFilter, LanguagePicker } from './languages';
export {
  ServiceCriticalityFilter,
  ServiceCriticalityPicker,
} from './service-criticality';
export { ToolingFilter, ToolingPicker } from './tooling';

export type CustomFilters = DefaultEntityFilters & {
  awsAccount: AwsAccountFilter;
  k8sCluster: K8sClusterFilter;
  language: LanguageFilter;
  serviceCriticality: ServiceCriticalityFilter;
  tooling: ToolingFilter;
  visibility: VisibilityFilter;
};

export const CustomEntityAutocompletePicker = EntityAutocompletePicker<
  CustomFilters,
  AllowedEntityFilters<CustomFilters>
>;
