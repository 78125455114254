import React from 'react';

import { EntityFilter } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { CustomEntityAutocompletePicker } from '.';
import { isCustomEntity } from '@internal/backstage-plugin-custom-entity-metadata-common';

export class ServiceCriticalityFilter implements EntityFilter {
  constructor(readonly values: string[]) {}

  getCatalogFilters(): Record<string, string[]> {
    return { 'metadata.custom.serviceCriticality': this.values };
  }

  filterEntity(entity: Entity): boolean {
    if (!this.values.length) {
      return true;
    }

    if (!isCustomEntity(entity)) {
      return false;
    }

    return this.values.includes(
      String(entity.metadata.custom.serviceCriticality),
    );
  }

  toQueryValue(): string | string[] {
    return this.values.join(',');
  }
}

export const ServiceCriticalityPicker = () => (
  <CustomEntityAutocompletePicker
    label="Criticality"
    name="serviceCriticality"
    path="metadata.custom.serviceCriticality"
    Filter={ServiceCriticalityFilter}
  />
);
