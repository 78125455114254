import { Entity } from '@backstage/catalog-model';

export const PROMETHEUS_ALERT_ANNOTATION = 'prometheus.io/alert';
export const PROMETHEUS_SERVICE_NAME_ANNOTATION = 'prometheus.io/service-name';
export const PROMETHEUS_ALERT_LABEL_ANNOTATION = 'prometheus.io/labels';
export const PROMETHEUS_PLUGIN_DOCUMENTATION =
  'https://gitlab.netrtl.com/rtlplus/devx/backstage-and-friends/backstage/-/blob/main/plugins/prometheus/README.md?ref_type=heads';

export const isPrometheusAlertAvailable = (entity: Entity) =>
  Boolean(entity?.metadata.annotations?.[PROMETHEUS_ALERT_ANNOTATION]);

export const getServiceName = (entity: Entity) =>
  entity?.metadata.annotations?.[PROMETHEUS_SERVICE_NAME_ANNOTATION];

export const getLabels = (entity: Entity) =>
  entity?.metadata.annotations?.[PROMETHEUS_ALERT_LABEL_ANNOTATION];
