import React from 'react';
import { EntityFilter } from '@backstage/plugin-catalog-react';
import { Entity } from '@backstage/catalog-model';
import { isCustomEntity } from '@internal/backstage-plugin-custom-entity-metadata-common';
import { CustomEntityAutocompletePicker } from '.';

export class VisibilityFilter implements EntityFilter {
  constructor(readonly values: string[]) {}

  getCatalogFilters(): Record<string, string[]> {
    return { 'metadata.custom.visibility': this.values };
  }

  filterEntity(entity: Entity): boolean {
    if (!this.values.length) {
      return true;
    }

    if (!isCustomEntity(entity)) {
      return false;
    }

    if (!entity.metadata.custom.visibility) {
      return false;
    }

    return this.values.includes(entity.metadata.custom.visibility);
  }

  toQueryValue(): string {
    return this.values.join(',');
  }
}

export const VisibilityPicker = () => (
  <CustomEntityAutocompletePicker
    label="Visibility"
    name="visibility"
    path="metadata.custom.visibility"
    Filter={VisibilityFilter}
  />
);
